import React from "react";
import { SecondaryPage } from "src/components/secondary-page";
import { SEO } from "src/components/SEO";

export interface PageProps {
	pageContext: {
		main: {
			title: string;
			slug: {
				current: string;
			};
			body: [];
		};
		meta: {};
	};
	path: string;
}

const Page = ({ path, pageContext }: PageProps) => {
	const {
		main: { body, title, slug },
		meta,
	} = pageContext;

	const url = slug.current === "home" ? "" : path;
	return (
		<>
			<SEO metaInfo={meta} pagePath={url} />
			<SecondaryPage body={body} title={title} />
		</>
	);
};

export default Page;
