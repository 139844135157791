import React from "react";
import cx from "classnames";
import { BlockCopy } from "src/components/global/block-copy";

import styles from "./secondary-page.module.css";

interface SecondaryPageProps {
	body: any;
	title: string;
}

export const SecondaryPage = ({ body, title }: SecondaryPageProps) => {
	return (
		<div className={styles.pageContainer}>
			<h1 className={cx("sans sans--caps-24 light", styles.title)}>{title}</h1>
			<BlockCopy className={styles.body} content={body} />
		</div>
	);
};
